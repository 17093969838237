<template>
  <div style="background: #f9f9f9">
    <van-overlay :show="showShare"
                 @click="showShare = false">
      <img src="../assets/images/share.png"
           alt
           class="share_img" />
    </van-overlay>
    <div v-if="skeleton"
         class="skeleton">
      <van-skeleton title
                    :row="20" />
    </div>
    <div class="card"
         v-else>
      <div class="desc">
        <div class="info">
          <div class="head">
            <img :src="form2.headPic"
                 alt
                 class="head_img" />
            <div class="pp">
              <p class="p1">{{ form2.name }}
                <img v-if="form2.openId"
                     class="cert_icon"
                     src="../assets/images/v.png"
                     alt="">
              </p>
              <p class="p2">{{ form2.post }}</p>
              <p class="p3">{{ form2.company }}</p>
            </div>
            <img :src="form2.companyLogo"
                 alt
                 class="company_img" />
          </div>
          <div class="item_info">
            <img src="../assets/images/图层 10.png"
                 alt />
            <span class="span2">{{ form2.mobile }}</span>
            <span class="span3"
                  @click="tel(form2.mobile)">Call 我</span>
          </div>
          <div class="item_info">
            <img src="../assets/images/图层 11.png"
                 alt />
            <span class="span2">{{ form2.email }}</span>
          </div>
          <div class="item_info">
            <img src="../assets/images/图层 16.png"
                 alt />
            <span class="span2">{{ form2.www || "www" }}</span>
            <span class="span3"
                  @click="browse(form2.www)">浏 览</span>
          </div>
          <div class="item_info">
            <img src="../assets/images/图层 12 拷贝.png"
                 alt />
            <span class="span2">{{ form2.address }}</span>
            <span class="span3"
                  @click="address">导 航</span>
            <!-- <img
              src="../assets/images/address.png"
              alt=""
              class="address_img"
            />-->
          </div>

          <div v-if="form2.isShowCompanyRole">
            <div class="more_post">更多职务</div>
            <div v-for="(item, i) in moreCompanys"
                 :key="i"
                 class="post_box">
              <img src="../assets/images/公司.png"
                   alt />
              <span class="span2">{{item.moreCompanyName}}</span>
              <span class="span2"
                    style="padding-left: 20px">{{item.moreCompanyRole}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="fun">
        <div class="fun_item"
             @click="shareCard">
          <img src="../assets/images/分享.png"
               alt />
          <p>发送名片</p>
        </div>
        <div class="fun_item"
             @click="qr_show(form2.qrCodeUrl, 9, true)">
          <img src="../assets/images/图片.png"
               alt />
          <p>图像名片</p>
        </div>
        <div class="fun_item"
             @click="qr_show(form2.wechatQrCode, 6)">
          <img src="../assets/images/二维码.png"
               alt />
          <p>立即加微信</p>
        </div>
        <div class="fun_item"
             v-if="form2.openId !== $cookies.get('openId')"
             @click="favorite()">
          <img :src="myFavoriteId ? favoritel : favoritel2"
               alt />
          <p>{{myFavoriteId ? '取消收藏' : '收藏名片'}}</p>
        </div>
        <div class="fun_item"
             @click="trailSys()">
          <img src="../assets/images/trail.png"
               alt />
          <p>试用系统</p>
        </div>
      </div>

      <van-swipe class="my-swipe"
                 :autoplay="3000"
                 indicator-color="white"
                 v-if="adverts.length && form2.isShowAd">
        <van-swipe-item v-for="(item, i) in adverts"
                        :key="i"
                        @click="intoAd(item)">
          <img :src="item.faceUrl"
               alt=""
               class="ad_img">
        </van-swipe-item>
      </van-swipe>

      <div class="intro"
           v-for="item in form2.cardEntryList"
           :key="item.id">
        <div v-if="item.isH5Show">
          <p class="title">{{ item.title }}</p>
          <p class="content"
             v-html="item.content"></p>
        </div>
      </div>
    </div>
    <qrcode v-model="qrshow"
            :src="qrcode"></qrcode>
    <van-overlay :show="show"
                 @click="show = false">
      <div class="wrapper"
           @click.stop>
        <img :src="qrcode"
             alt=""
             class="img_img" />
        <img src="../assets/images/关闭.png"
             alt=""
             class="close"
             @click="show = false" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import mixin from "../utils/share";
import ladingPage from "../utils/ladingPage";
import qrcode from "@/components/qrcode";
export default {
  mixins: [mixin, ladingPage],

  props: {},
  data () {
    return {
      postTypes: [],
      moreCompanys: [],
      favoritel: require('../assets/images/收藏.png'),
      favoritel2: require('../assets/images/收藏2.png'),
      myFavoriteId: null,
      showShare: false,
      qrshow: false,
      qrcode: "",
      form2: {
        businessCard: {},
        ad: {},
        article: {},
      },
      show2: false,
      url: window.location.href,
      show: false,
      adverts: [
        // {
        //   faceUrl: "http://scp.zhongpukeji.cn/f/image/1671786427730.png",
        //   id: 1060106119348224,
        //   ladingPage: "www.zhongpukeji.cn"
        // },
        // {
        //   faceUrl: "http://scp.zhongpukeji.cn/f/image/1662260062428.png",
        //   id: 1060106119348222,
        //   ladingPage: ""
        // },
        // {
        //   faceUrl: "http://scp.zhongpukeji.cn/f/image/1656830899264.png",
        //   id: 1060106119348227,
        //   ladingPage: "https://mp.weixin.qq.com/s/5-JiH3AykGc1d0TKl5oiGA"
        // }
      ]
    };
  },
  computed: {},
  created () {
    this.$api
      .get("/api/crm/businessCard/get", { id: this.$route.query.id })
      .then((res) => {
        this.form2 = res.result;
        this.form.businessCard = res.result;

        localStorage.setItem("tel", this.form.businessCard.mobile);
        localStorage.setItem("www", this.form.businessCard.www);
        localStorage.setItem("id", this.form.id);
        localStorage.setItem("businessCardId", this.form.businessCard.id);
        localStorage.setItem(
          "address",
          `http://apis.map.qq.com/uri/v1/marker?marker=coord:${this.form.businessCard.lat},${this.form.businessCard.lng};title:${this.form.businessCard.company};addr:${this.form.businessCard.address}`
        );
        localStorage.setItem("productUrl", this.form.businessCard.productUrl);
        localStorage.setItem("openProduct", this.form.businessCard.openProduct);
        this.share();
        this.geet();
        this.$nextTick(() => {
          this.skeleton = false;
        });

        this.getFavoriteStatus()
        this.getMoreCompanys()
        this.getAdverts()

        if (this.$route.query.share) {
          this.shareCard()
        }
      });
    // this.Action(3);
  },
  mounted () {
  },
  watch: {},
  methods: {
    trailSys () {
      // this.$api
      //   .post("/api/crm/article/list", {
      //     pageNum: 1,
      //     pageSize: 1,
      //     orderByDesc: "createTime",
      //     formId: 1301960739979264, // 【我公司要上供应链公司软件系统】表单
      //   })
      //   .then((res) => {
      //     let array = res.result.list || []
      //     if (array[0]) {
            
      //     }
      //   });
      this.$router.push(
        `/trail?id=${localStorage.getItem("ladingPageId") || 0
        }&businessCardId=${this.form.businessCard.id}&channelId=${localStorage.getItem("channelId") || 0
        }&formId=1301960739979264`
      );
    },
    intoAd (ad) {
      if (ad.ladingPage) {
        window.location.href = ad.ladingPage
      }
    },
    getMoreCompanys () {
      if (this.form2.moreCompanyName) {
        let company = this.form2.moreCompanyName.split(',')
        let role = this.form2.moreCompanyRole.split(',')
        this.moreCompanys = []
        company.forEach((item, i) => {
          this.moreCompanys.push({
            moreCompanyName: item,
            moreCompanyRole: role[i]
          })
        })
      }
    },
    favorite () {
      if (this.myFavoriteId) { // 取消收藏
        this.$api
          .post('/api/crm/businessCardFavorite/del', { id: this.myFavoriteId })
          .then((res) => {
            if (res.code === 'C0000') {
              this.$toast({
                message: "取消收藏成功",
                className: "_toast"
              })
              this.getFavoriteStatus()
            }
          })
      } else { // 收藏名片
        this.$api
          .post('/api/crm/businessCardFavorite/add', {
            businessCardId: this.form.businessCard.id,
            companyId: this.form.businessCard.companyId,
            openId: this.$cookies.get('openId'),
            wechatUserId: null
          })
          .then((res) => {
            if (res.code === 'C0000') {
              this.$toast({
                message: "收藏成功",
                className: "_toast"
              })
              this.getFavoriteStatus()
            }
          })
      }

    },
    getFavoriteStatus () {
      this.$api
        .post('/api/crm/businessCardFavorite/list', {
          data: {
            openId: this.$cookies.get('openId'),
            businessCardId: this.form.businessCard.id
          }
        })
        .then((res) => {
          if (res.code === 'C0000') {
            if (res.result && res.result.list && res.result.list.length) {
              this.myFavoriteId = res.result.list[0].id
            } else {
              this.myFavoriteId = null
            }
          }
        })
    },
    getAdverts () {
      this.$api
        .post('/api/crm/ad/frontList', {
          data: {
            companyId: this.form.businessCard.companyId
          }
        })
        .then((res) => {
          if (res.code === 'C0000') {
            if (res.result && res.result.list && res.result.list.length) {
              this.adverts = res.result.list
            } else {
            }
          }
        })
    },
    browse (url) {
      if (url.indexOf("http") == -1) {
        window.location.href = "http://" + url;
      } else {
        window.location.href = url;
      }
    },
    qr_show (src, num, flag) {
      this.qrcode = src;
      if (!flag) {
        this.qrshow = true;
      } else {
        this.show = true;
      }
      this.Action(num);
    },
    back () {
      this.$router.push(`/?id=${this.$route.query.id}`);
    },
    shareCard (num) {
      if (num == 1) {
        this.$router.push("/cardDetails");
        return false;
      }
      this.showShare = true;
      // this.Action(9);
    },
    tel (value) {
      window.location.href = `tel:${value}`;
      this.Action(8);
    },
    address () {
      window.open(
        // `http://apis.map.qq.com/uri/v1/marker?marker=coord:39.892326,116.342763;title:超好吃冰激凌;addr:手帕口桥北铁路道口`
        `http://apis.map.qq.com/uri/v1/marker?marker=coord:${this.form.businessCard.lat},${this.form.businessCard.lng};title:${this.form.businessCard.company};addr:${this.form.businessCard.address}`
      );
    },
  },
  components: { qrcode },
};
</script>
<style scoped lang="less">
.my-swipe {
  height: 180px;
  width: 100%;
  .van-swipe-item {
    text-align: center;
  }
}
.ad_img {
  width: 100%;
  height: 180px;
}
.wrapper {
  // position: relative;
}
.img_img {
  width: 70%;
  display: block;
  height: auto;
  margin: 0 auto;
  margin-top: 60px;
}
.close {
  width: 72px;
  height: 72px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -100px;
}
.back_btn {
  height: 88px;
  background: #ff6e03;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 88px;
  width: 90%;
  // position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  background: #fff;
  border: 1px solid #ccc;
  color: #363636;
  margin-bottom: 40px;
}
.value {
  display: flex;
  justify-content: inherit;
  width: 100%;
}
.address_img {
  width: 32px;
  height: 30px;
  position: relative;
  right: 20px;
}
.intro {
  background: #ffffff;
  border-radius: 16px;
  width: 100%;
  margin: 20px auto;
  .title {
    font-size: 33px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    padding: 35px 30px;
  }
  .content {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #363636;
    line-height: 48px;
    padding: 0 28px;
    padding-bottom: 40px;
  }
}
.share_img {
  width: 320px;
  height: 290px;
  margin-top: 20px;
  margin-left: 320px;
}
.btns {
  background: #f9f9f9;
  height: 220px;
  overflow: hidden;
  .btn {
    margin-top: 32px;
    text-align: center;
  }
  .other {
    display: flex;
    justify-content: space-between;
    padding: 0 45px;
    margin-top: 40px;
    align-items: center;
    .img {
      img {
        width: 42px;
        height: 42px;
        margin-right: 6px;
      }
    }
    .value {
      img {
        width: 28px;
        height: 20px;
      }
    }
  }
  .btn1 {
    width: 308px;
    height: 83px;
    background: #ffffff;
    border: 1px solid #ff6e03;
    outline: 0;
    margin-right: 22px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff6e03;
  }
  .btn2 {
    width: 308px;
    height: 83px;
    background: #ff6e03;
    border: 0;
    outline: 0;
    margin-left: 22px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.fun {
  width: 100%;
  height: 180px;
  background: #ffffff;
  border-radius: 16px;
  margin: 20px auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .fun_item {
    width: 160px;
    text-align: center;
    img {
      width: 50px;
      height: 50px;
    }
    p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      padding-top: 20px;
      color: #333333;
    }
  }
}
.card {
  width: 100%;
  background: url("../assets/images/组 1.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 100px 30px 20px;
  box-sizing: border-box;

  .bg_img {
    height: 516px;
    width: 100%;
  }
  .more_post {
    padding-left: 80px;
    display: flex;
    align-items: center;
    color: #888;
    padding-bottom: 10px;
  }
  .post_box {
    padding-left: 40px;
    padding-bottom: 20px;
    img {
      width: 28px;
      height: 28px;
      margin-right: 12px;
      vertical-align: -0.2em;
    }
    .span2 {
      font-size: 25px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #4a4a4a;
      flex: 1;
      padding-right: 30px;
    }
  }
  .item_info {
    padding-left: 40px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    img {
      width: 28px;
      height: 28px;
      margin-right: 12px;
      vertical-align: -0.2em;
    }
    .span2 {
      font-size: 25px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #4a4a4a;
      flex: 1;
      padding-right: 30px;
    }
    .span3 {
      width: 77px;
      height: 27px;
      background: #ffffff;
      border: 1px solid #ff6e03;
      border-radius: 14px;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff6e03;
      margin-right: 28px;
    }
  }
  .desc {
    .info {
      width: 100%;
      margin: auto;
      background: #fff;
      border-radius: 10px;
      .head {
        display: flex;
        padding-top: 36px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
        .head_img {
          width: 128px;
          height: 128px;
          border-radius: 50%;
        }
        .cert_icon {
          height: 40px;
          vertical-align: -0.2em;
        }
        .company_img {
          width: 100px;
          height: 100px;
        }
        .pp {
          flex: 1;
          padding-left: 30px;
          line-height: 30px;
          padding-top: 10px;
          .p1 {
            font-size: 37px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000001;
          }
          .p2 {
            font-size: 25px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #4a4a4a;
            padding-top: 16px;
          }
          .p3 {
            font-size: 25px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #4a4a4a;
            padding-top: 10px;
          }
        }
      }
    }
  }
}
</style>
